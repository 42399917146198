import styled from "styled-components";

export const Container = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;

  @media (min-width: ${(props) => props.theme.screen.xs}) {
    max-width: 540px;
  }

  @media (min-width: ${(props) => props.theme.screen.sm}) {
    max-width: 720px;
  }

  @media (min-width: ${(props) => props.theme.screen.md}) {
    max-width: 960px;
  }

  @media (min-width: ${(props) => props.theme.screen.lg}) {
    max-width: 1200px;
  }

  ${(props) =>
    props.fluid &&
    `
    max-width: 1200px !important;
  `};
`;

export const Section = styled.section`
  padding: ${(props) => (props.author ? "120px 0" : "80px 0")};
  margin-top: ${(props) => (props.anchor ? "-80px" : "0")};
  overflow: hidden;
  background-color: ${(props) => {
    switch (props.accent) {
      case "secondary":
        return props.theme.color.white.dark;
      case "main":
        return props.theme.color.primary;
      default:
        return "white";
    }
  }};

  @media (max-width: ${(props) => props.theme.screen.md}) {
    // padding: 80px 0;
  }

  ${(props) =>
    props.accent &&
    `background-color: ${
      props.accent === "secondary"
        ? props.theme.color.white.dark
        : props.theme.color.primary
    }`};
`;

export const GridContainer = styled.div`
  display: grid;
  column-gap: 2rem;
  grid-template-columns: repeat(
    auto-${(props) => (props.fit ? "fit" : "fill")},
    minmax(242px, 1fr)
  );
`;

export const Boxed = styled.div`
  width: 90vw;
  max-width: ${(props) =>
    props.fixedWidth ? "var(--fixed-width)" : "var(--max-width)"};
  margin: 0 auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  // margin-left: auto;
  // margin-right: auto;

  @media (max-width: 750px) {
    padding-left: 0;
    padding-right: 0;
  }
`;
