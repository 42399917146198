import React from "react";
import { getImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";

import { Section } from "../common/layout/containers";
import HomeShowCase from "./home-show-case";
import {
  FeatureText,
  GetStartedContainer,
  LinkStyledCustom,
} from "../sections-styles";

const Book = ({ page }) => {
  const { file } = useStaticQuery(query);
  const image = getImage(file.childImageSharp.gatsbyImageData);

  return (
    <Section id="31JoursDePriere" anchor>
      <HomeShowCase
        image={image}
        fileName="vers le mari de ma destinée"
        title="31 jours de prière pour ton futur mari"
        page={page}
      >
        <FeatureText>
          Les résultats d’un sondage sur les critères du choix du bon mari,
          effectué par l’auteure auprès de femmes célibataires chrétiennes, ont
          révélé que leur préoccupation principale était : « comment reconnaitre
          la bonne personne ».
        </FeatureText>
        <FeatureText>
          <strong>{`31 jours de prière pour ton futur mari `}</strong> est un
          guide pratique de prière, inspiré par le Saint-Esprit, qui répond
          clairement à cette question. En effet, en s’appuyant sur des principes
          bibliques, l’auteure emmène la lectrice à disposer son cœur à recevoir
          le dessein de mariage que Dieu a préparé pour elle et à renouveler son
          intelligence quant aux qualités d’un bon mari selon Dieu.
        </FeatureText>
        <FeatureText>
          Contrairement à plusieurs ouvrages qui ont été publiés avant lui, ce
          livre ne « sert » pas que de la théorie ou des phrases toutes faites
          qui ne répondent pas véritablement aux besoins de la célibataire
          chrétienne. Mais, il donne des outils pratiques, apporte de précieux
          témoignages, exprime des remarques pertinentes et conduit dans la
          prière fervente qui est d’une grande efficacité. C’est le parfait
          combo pour non seulement reconnaitre ton futur mari, mais aussi te
          préparer à rentrer dans le plan parfait de mariage que le Seigneur a
          formé pour toi.
        </FeatureText>
      </HomeShowCase>
      <GetStartedContainer>
        <LinkStyledCustom
          href="https://verslemaridemadestinee.com/#buyBook"
          target="_blank"
        >
          Acheter ce livre
        </LinkStyledCustom>
      </GetStartedContainer>
    </Section>
  );
};

export default Book;

const query = graphql`
  {
    file(
      sourceInstanceName: { eq: "book" }
      name: { eq: "book-show-case-with-text-green-center-2560x1440" }
    ) {
      name
      childImageSharp {
        gatsbyImageData(
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
          quality: 60
        )
      }
    }
  }
`;
