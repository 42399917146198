import React from "react";
import { getImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";

import { Section } from "../common/layout/containers";
import {
  FeatureText,
  GetStartedContainer,
  LinkStyledCustom,
} from "../sections-styles";
import HomeShowCase from "./home-show-case";
// import Reviews from "../reviews/Reviews"
// import Message from "../ui/Message";

const EBook = ({ page }) => {
  const { file } = useStaticQuery(query);
  const image = getImage(file.childImageSharp.gatsbyImageData);

  return (
    <Section id="50Questions" anchor>
      <HomeShowCase
        image={image}
        fileName="Ebook 50 Questions à se poser avant de s’engager de Sammantha Thom"
        title="50 Questions à se poser avant de s’engager"
        // titleSpan="Les critères d'un bon mari"
        page={page}
      >
        <FeatureText>
          Faut-il se battre pour se marier avant ses 30 ans ? Peut-on changer un
          homme irresponsable ? Comment savoir si l’on est prête à se marier ou
          si l’on a juste envie de combler la solitude ?
        </FeatureText>
        <FeatureText>
          Ce sont là quelques-unes des préoccupations qu’ont exprimées près de
          700 femmes célibataires dans un sondage intitulé
          <strong>{` Le choix du bon mari. `}</strong>
          De ce lot de centaines de questions, Sammantha Thom, a rigoureusement
          sélectionné les plus pertinentes : celles que toute célibataire
          devrait se poser avant de s’engager, afin de mûrir sa compréhension du
          mariage. L’auteure a ensuite invité des femmes mariées depuis trois à
          plus de quarante ans à répondre à ces questions, et a composé ce
          précieux petit ouvrage.
        </FeatureText>
        <Message>
          Si tu achètes le livre
          <span>{` 31 Jours de prière pour ton futur mari `}</span>avant le 14
          novembre et nous envoie ta preuve d’achat par courriel, tu recevras
          gratuitement cet e-book.
        </Message>
      </HomeShowCase>
      <GetStartedContainer>
        <LinkStyledCustom
          href="https://buy.stripe.com/7sIbMIgHT3yw1W0aEE"
          target="_blank"
        >
          Acheter cet e-book
        </LinkStyledCustom>
      </GetStartedContainer>
      {/* <Reviews reviews={reviewList}></Reviews> */}
    </Section>
  );
};

export default EBook;

const query = graphql`
  {
    file(
      sourceInstanceName: { eq: "book" }
      name: { eq: "50-questions-a-se-poser-avant-de-s-engager" }
    ) {
      name
      childImageSharp {
        gatsbyImageData(
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
          quality: 60
        )
      }
    }
  }
`;

const Message = styled.div`
  border: 1px solid #e5eff5;
  boxshadow: 0 2px 3px rgb(3 27 78 / 5%);
  borderradius: var(--radius);
  padding: 2rem 1.5rem;
  margin: 4rem 0;
  text-align: center;
  background: var(--clr-grey-9);
  span {
    font-style: italic;
    font-weight: bold;
  }
`;
