import React from "react";
// import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Book from "../components/book/book";
import Ebook1 from "../components/book/ebook1";
import Ebook2 from "../components/book/ebook2";

const books = ({ data }) => {
  // const {
  //   allContentfulProgram: { nodes: programs },
  // } = data;

  return (
    <Layout>
      <Seo
        title="Livres de Sammantha Thom"
        description="Les livres de Sammantha Thom"
      />
      <Book />
      <Ebook2 />
      <Ebook1 />
    </Layout>
  );
};

// export const query = graphql`
//   {
//     allContentfulProgram(sort: { order: ASC, fields: createdAt }) {
//       nodes {
//         content {
//           content
//         }
//         featured
//         title
//         image {
//           gatsbyImageData(
//             layout: FULL_WIDTH
//             width: 100
//             placeholder: BLURRED
//             formats: [AUTO, WEBP]
//           )
//         }
//       }
//     }
//   }
// `;

export default books;
